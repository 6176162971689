import React from 'react';
import styled from 'styled-components';

const BannerContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  padding: 15px;
  z-index: 1000;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;

  button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;

    &:hover {
      background-color: darkred;
    }
  }
`;

const CookieBanner = ({ onAccept, onReject }) => {
  return (
    <BannerContainer>
      <p>Questo sito utilizza cookie per offrirti un'esperienza migliore. Accetti?</p>
      <ButtonContainer>
        <button onClick={onAccept}>Accetta</button>
        <button onClick={onReject}>Rifiuta</button>
      </ButtonContainer>
    </BannerContainer>
  );
};

export default CookieBanner;
