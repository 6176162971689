import styled from 'styled-components';

const backgroundImageUrl = process.env.REACT_APP_backgroundPhoto;

export const Title = styled.h1`
    font-size: 4rem;
    color: #FFFFFF;
    

    @media (max-width: 768px) {
        text-align: center;
    }
`;

export const SubTitle = styled.h2`
    color: #FFFFFF;

    @media (max-width: 768px) {
        text-align: center;
    }
`;

export const AppContainer = styled.div`
`;

export const AppHeader = styled.header`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: calc(10px + 2vmin);

    background-color: ${(props) => props.theme.colors.backgroundColor};
    color: #FFFFFF;
`;

export const FullBackgroundPhoto = styled.div`
    background-image: url(${backgroundImageUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: right;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 80px;

    @media (max-width: 1000px) {
        padding: 0 40px;
    }

    @media (max-width: 768px) {
        padding: 0 20px;
    }
`;