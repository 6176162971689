import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import SocialBar from "../../components/SocialBar";
import { useSocials } from "../../context/SocialContext";

const Sidebar = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 60px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    @media (max-width: 768px) {
        width: 100%;
        align-items: flex-start;
        padding-left: 20px;
    }
`;

const SidebarItem = styled(Link)`
    margin: 20px 0;
    cursor: pointer;
    font-size: 1.2em;
    color: ${(props) => props.theme.colors.textLight};
    text-decoration: none;

    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);

    &:hover {
        color: ${(props) => props.theme.colors.highlight};
    }

    @media (max-width: 768px) {
        writing-mode: horizontal-tb;
        transform: rotate(0);
        font-size: 2em;
    }
`;

const MobileSocialBar = styled.div`
    display: none;

    @media (max-width: 768px) {
        display: block;
        width: 100%;
    }
`;

export default function VerticalSidebar() {
  const { socials, loading } = useSocials();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Sidebar>
      <SidebarItem to="/#home">Home</SidebarItem>
      <SidebarItem to="/#cocktails">Cocktails</SidebarItem>
      <SidebarItem to="/#about">About Us</SidebarItem>
      <SidebarItem to="/#contact">Contact</SidebarItem>
      <MobileSocialBar>
        <SocialBar socials={socials} />
      </MobileSocialBar>
    </Sidebar>
  );
}
