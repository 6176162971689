import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        ${props => props.tabletasmobile && `
            flex-direction: column;
        `}
    }
`;

const LeftColumn = styled.div`
    flex: ${props => props.width};
    box-sizing: border-box;

    @media (max-width: 768px) {
        order: ${props => props.mobileorderleft};
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        align-items: center;
        display: flex;
        justify-content: center;
        order: ${props => props.mobileorderleft};
    }
`;

const RightColumn = styled.div`
    flex: ${props => props.width};
    box-sizing: border-box;

    @media (max-width: 768px) {
        order: ${props => props.mobileorderright};
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        align-items: center;
        display: flex;
        justify-content: center;
        order: ${props => props.mobileorderright};
    }
`;

const DoubleColumnComponent = ({
                                 leftContent,
                                 rightContent,
                                 leftColumnWidth,
                                 mobileOrder = 'default',
                                 tabletAsMobile = false
                               }) => {
  const leftWidth = parseFloat(leftColumnWidth) || 0;
  const rightWidth = 100 - leftWidth;

  const getMobileOrder = (order) => {
    if (order === 'leftFirst') {
      return { mobileorderleft: 1, mobileorderright: 2 };
    } else if (order === 'rightFirst') {
      return { mobileorderleft: 2, mobileorderright: 1 };
    } else {
      // Default order
      return { mobileorderleft: 1, mobileorderright: 2 };
    }
  };

  const { mobileorderleft, mobileorderright } = getMobileOrder(mobileOrder);

  return (
    <Container tabletasmobile={tabletAsMobile}>
      <LeftColumn
        id={"left"}
        width={`${leftWidth}%`}
        mobileorderleft={mobileorderleft}
        tabletasmobile={tabletAsMobile}
      >
        {leftContent}
      </LeftColumn>
      <RightColumn
        id={"right"}
        width={`${rightWidth}%`}
        mobileorderright={mobileorderright}
        tabletasmobile={tabletAsMobile}
      >
        {rightContent}
      </RightColumn>
    </Container>
  );
};

export default DoubleColumnComponent;
