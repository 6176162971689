import {handleRequest} from "./APIUtils";
const apiUrl = process.env.REACT_APP_API_URL;

export async function fetchLandingPageData() {
  try {
    const url = `${apiUrl}/frontend.php?action=landingPage`;
    return await handleRequest(url);
  } catch (error) {
    throw error;
  }
}