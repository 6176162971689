import ErrorPage from "../themes/component/ErrorPage";
import {Title} from "../themes/styled";

export default function CocktailErrorPage() {

  return (
    <ErrorPage>
      <>
        <Title>Il Cocktail non è disponibile</Title>
      </>
    </ErrorPage>
  );
}