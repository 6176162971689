import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import ThemeProviderComponent from "./ThemeProviderComponent";
import VerticalSidebar from "./component/VerticalSidebar";
import {SocialsProvider} from "../context/SocialContext";
import {FaBars} from "react-icons/fa";

const backgroundImageUrl = process.env.REACT_APP_backgroundPhoto;

const LayoutContainer = styled.div`
    display: flex;
    background-image: url(${backgroundImageUrl});
    background-attachment: fixed;
    background-size: cover;
    color: ${(props) => props.theme.colors.textRed};

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const AppContainer = styled.div`
    @media (max-width: 768px) {
        padding: 15px;
    }
`;

const SideBarAppHeader = styled.header`
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: calc(10px + 2vmin);
  `;

const Content = styled.div`
    flex: 1;
    margin-top: 30px;
    margin-left: 60px;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (max-width: 768px) {
        margin-left: 0;
    }
`;

const HiddenSidebar = styled.div`
    @media (max-width: 768px) {
        display: none;
    }
`;

const MobileSidebarButton = styled.button`
    display: none;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 10;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    background: none;
    color: white;

    @media (max-width: 768px) {
        display: block;
    }
`;

const MobileSidebar = styled.div`
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    width: 85%;
    height: 100vh;
    background-color: rgba(125, 0, 8, 0.59);
    z-index: 9;

    @media (min-width: 769px) {
        display: none;
    }
`;

export default function SidebarLayout({children}) {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  useEffect(() => {
    if (isSidebarOpen) {
      // Disabilita lo scroll del body quando la sidebar è aperta
      document.body.style.overflow = 'hidden';
    } else {
      // Riabilita lo scroll quando la sidebar è chiusa
      document.body.style.overflow = '';
    }

    // Cleanup per rimuovere lo stile se il componente viene smontato
    return () => {
      document.body.style.overflow = '';
    };
  }, [isSidebarOpen]);

  return (
    <ThemeProviderComponent>
      <SocialsProvider>
      <LayoutContainer>
        <HiddenSidebar>
          <VerticalSidebar/>
        </HiddenSidebar>
        <MobileSidebarButton onClick={toggleSidebar}>
          <FaBars color="white" />
        </MobileSidebarButton>
        <MobileSidebar isOpen={isSidebarOpen}>
          <VerticalSidebar />
        </MobileSidebar>
        <Content id={"content"}>
          <AppContainer>
            <SideBarAppHeader>{children}</SideBarAppHeader>
          </AppContainer>
        </Content>
      </LayoutContainer>
      </SocialsProvider>
    </ThemeProviderComponent>
  );
};
