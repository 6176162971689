import React, { createContext, useContext, useEffect, useState } from 'react';
import {getSocials} from "../api/Social";
// Crea il contesto
const SocialsContext = createContext();

export const useSocials = () => useContext(SocialsContext);

export const SocialsProvider = ({ children }) => {
  const [socials, setSocials] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSocials()
      .then((data) => {
        setSocials(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Errore durante il fetch dei socials:", error);
        setLoading(false);
      });
  }, []);

  return (
    <SocialsContext.Provider value={{ socials, loading }}>
      {children}
    </SocialsContext.Provider>
  );
};
