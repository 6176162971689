import React from 'react';
import styled from 'styled-components';
import {useRouteError} from "react-router-dom";

export default function ErrorPage({children}) {
  const error = useRouteError();
  console.error(error);

  const Container = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
  `;

  return (
    <Container>
      {children}
    </Container>
  );
}
