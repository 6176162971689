import React from 'react';
import styled from 'styled-components';
import { Title } from '../../themes/styled';
import SocialBar from '../SocialBar';

const backgroundImageUrl = 'https://liquorebonito.it/background/bottle.png';

const HomeDiv = styled.div`
    background-image: url(${backgroundImageUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    text-align: right;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    padding: 0 80px;

    @media (max-width: 768px) {
        height: 100vh;
        padding: 0 0px;
        background-image: none;
        align-items: flex-start;
        justify-content: flex-start; /* Allinea i contenuti al margine sinistro su dispositivi mobili */
    }
`;

const TextContainer = styled.div`
    width: 60%;
    padding: 30px;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;

    @media (max-width: 768px) {
        margin-left: 0;
        width: 100%;
    }
`;

const Text = styled.p`
    width: 100%;
    text-align: justify;
    font-size: 1.5rem;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

const TitleContainer = styled.div`
    padding-bottom: 5%;
`;

const SocialBarContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export default function Home({ content, socials }) {

  return (
    <HomeDiv>
      <TextContainer>
        <TitleContainer>
          <Title>Liquore Bonito</Title>
        </TitleContainer>
        <Text>{content.text}</Text>
        <SocialBarContainer>
          <SocialBar socials={socials} />
        </SocialBarContainer>
      </TextContainer>
    </HomeDiv>
  );
}
