import React from 'react';
import { useRouteError } from "react-router-dom";
import { Title } from '../themes/styled';
import ErrorPage from "../themes/component/ErrorPage";

export default function RouteErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <ErrorPage>
      <Title>La Pagina Non Esiste</Title>
    </ErrorPage>
  );
}