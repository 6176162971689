import React from 'react';
import styled from 'styled-components';

const CardLink = styled.a`
    text-decoration: none;
    color: inherit;
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
`;

const ImageContainer = styled.div`
    width: 100%;
    height: 85%;
    margin-top: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const Image = styled.img`
    height: 100%;
    width: auto;
    object-fit: cover;
    border-radius: 8px;
`;

const TitleContainer = styled.div`
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
`;

const Title = styled.h2`
    font-size: 2.5rem;
    margin: 0;
    text-align: center;
    color: #FFFFFF;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

const CardWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-top: 150%;
`;

const CardContentWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
`;

export default function Card ({ index, title, image }) {
  return (
    <CardLink href={`/cocktail/${index}`}>
      <CardWrapper>
        <CardContentWrapper>
          <CardContainer>
            <ImageContainer>
              <Image src={image} alt={title} />
            </ImageContainer>
            <TitleContainer>
              <Title>{title}</Title>
            </TitleContainer>
          </CardContainer>
        </CardContentWrapper>
      </CardWrapper>
    </CardLink>
  );
};
