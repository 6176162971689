import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import styled from "styled-components";
import ThemeProviderComponent from "../themes/ThemeProviderComponent";
import {getCocktail} from '../api/Cocktail';
import LoadingSpinner from '../components/LoadingSpinner';
import SocialBar from "../components/SocialBar";
import DoubleColumnComponent from "../themes/DoubleColumnComponent";
import CocktailErrorPage from "../errorPages/cocktailErrorPage";
import {Title} from "../themes/styled";

const IngredientWithSymbol = ({quantity, symbol, name}) => (<p>{name} {quantity} {symbol}</p>);
const IngredientWithoutSymbol = ({quantity, unit, name}) => (<p>{quantity} {unit} {name}</p>);

const Header = styled.div`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      color: white;
  `;

const StyledTitle = styled(Title)`
      margin-bottom: 3%;
      color: white;
  `;

const Container = styled.div`
      height: 10%;
      display: flex;
      flex-direction: column;
  `;

const LeftColumnContentWrapper = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      overflow: hidden;
  `;

const LeftColumnContent = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;
      height: 100%;
      max-width: 100%;
      overflow: hidden;

      @media (max-width: 768px) {
          width: 95%;
          height: 100%;
          max-width: 100%;
      }
      
  `;

const ImageContainer = styled.div`
      background-color: rgba(0, 0, 0, 0.8);
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px; /* Aggiungi spazio sotto l'immagine */

      @media (max-width: 768px) {
          padding: 10px;
          width: 100%;
          
          margin-top: 20px;
          margin-bottom: 20px; /* Mantieni margine sotto l'immagine su mobile */
      }
  `;

const Image = styled.img`
      width: 100%;
      height: auto;
      max-width: calc(100% - 40px); /* Compensa il padding del contenitore */
      max-height: calc(100% - 40px); /* Compensa il padding del contenitore */
      object-fit: contain;
      border-radius: 8px;
      margin: 20px; /* Aggiunge margine uniforme su tutti i lati */

      @media (max-width: 768px) {
          margin-bottom: 20px;
      }
  `;


const RightColumnContent = styled.div`
      color: white; /* Imposta il colore del testo nella colonna di destra */
      font-size: 1.5rem; /* Imposta la dimensione del testo a 1.5rem */

      @media (max-width: 768px) {
          margin-bottom: 20px; /* Aggiunge margine inferiore per distanziare le icone social dal bordo */
      }
  `;

export default function Cocktail() {
  const {cocktailId} = useParams();
  const [cocktail, setCocktail] = useState(null);
  const [socials, setSocials] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    getCocktail(cocktailId)
      .then(data => {
        const dataParsed = JSON.parse(data);
        const socials = dataParsed.social.map((social) => JSON.stringify({
          nome: social.social_platform, link: social.url_social
        }));
        setSocials(socials);
        setCocktail(dataParsed);
      })
      .catch(e => {
        setError(true);
      });

    // Applica overflow hidden solo se la larghezza dello schermo è superiore a 768px
    const handleResize = () => {
      document.body.style.overflow = window.innerWidth > 768 ? 'hidden' : '';
    };

    handleResize(); // Imposta l'overflow inizialmente
    window.addEventListener('resize', handleResize); // Aggiungi listener per il ridimensionamento

    return () => {
      document.body.style.overflow = ''; // Ripristina overflow al termine del ciclo di vita
      window.removeEventListener('resize', handleResize); // Rimuovi listener al termine del ciclo di vita
    };
  }, [cocktailId]);

  const parseIngredienti = (list) => {
    if (Array.isArray(list)) {
      return (<>
        {list.map((item) => item.symbol ? (
          <IngredientWithSymbol key={item.id_ingredient} quantity={item.quantity} symbol={item.symbol}
                                name={item.nome_ingredient}/>) : (
          <IngredientWithoutSymbol key={item.id_ingredient} quantity={item.quantity} unit={item.unit}
                                   name={item.nome_ingredient}/>))}
      </>);
    } else {
      console.error('I dati degli ingredienti non sono un array.');
      return <p>Errore nel formato degli ingredienti.</p>;
    }
  };

  if (error)
    return (
      <>
        <CocktailErrorPage/>
      </>
    )

  return (cocktail || socials) ? (<ThemeProviderComponent>
    <Header>
      <StyledTitle>{cocktail.nomeCocktail}</StyledTitle>
    </Header>
    <Container>
      <DoubleColumnComponent
        leftContent={
        <LeftColumnContentWrapper>
          <LeftColumnContent>
            <ImageContainer>
              <Image src={cocktail.urlImgCocktail} alt={cocktail.nomeCocktail}/>
            </ImageContainer>
          </LeftColumnContent>
        </LeftColumnContentWrapper>
        }
        rightContent={
          <RightColumnContent>
            <div style={{maxHeight: '50%', overflowY: 'auto'}}>
              {parseIngredienti(cocktail.ingredienti)}
            </div>
            <div style={{flexGrow: 1, paddingTop: '20px'}}>
              <p>{cocktail.descrizioneCocktail}</p>
              <SocialBar socials={socials}/>
            </div>
          </RightColumnContent>
        }
        leftColumnWidth={50}
        mobileOrder="default"
        style={{height: '100%'}}
      />
    </Container>
  </ThemeProviderComponent>) : <LoadingSpinner/>;
}
