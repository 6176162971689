import React from 'react';
import styled from 'styled-components';
import { Title } from '../../themes/styled';
import ImageAndText from "../../themes/component/ImageAndText";

const AboutContainer = styled.div`
    text-align: left;
    padding: 0 80px;

    @media (max-width: 768px) {
        padding: 0 20px;
        text-align: center;
    }
`;

const ImageAndTextWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 40px; // O la dimensione di margin che preferisci
  }
`;

export default function About({ content }) {

  return (
    <AboutContainer>
      <Title>About Us</Title>
      {content.map((item, index) => (
        <ImageAndTextWrapper key={index}>
          <ImageAndText
            content={item}
            imagePosition={index % 2 === 0 ? 'left' : 'right'}
          />
        </ImageAndTextWrapper>
      ))}
    </AboutContainer>
  );
}
