import styled from "styled-components";
import DoubleColumnComponent from "../DoubleColumnComponent";
import React from "react";

const TextContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        padding: 0;
    }
`;

const Text = styled.p`
    font-size: 1.5rem;
    padding: 30px;

    @media (max-width: 768px) {
        padding: 0;
    }
`;

const Image = styled.img`
    width: 100%;
    height: auto;
    max-width: 600px;
    aspect-ratio: 3 / 2;

    @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
`;

export default function ImageAndText ({ content, imagePosition }) {
  return (
    <>
      {imagePosition === 'left' && (
        <DoubleColumnComponent
          leftContent={content.image && <Image src={content.image} alt="" />}
          rightContent={
            <TextContainer>
              <Text>{content.text}</Text>
            </TextContainer>
          }
          leftColumnWidth="30"
          mobileOrder="rightFirst"
          tabletAsMobile={true}
        />
      )}
      {imagePosition === 'right' && (
        <DoubleColumnComponent
          leftContent={
            <TextContainer>
              <Text>{content.text}</Text>
            </TextContainer>
          }
          rightContent={content.image && <Image src={content.image} alt="" />}
          leftColumnWidth="70"
          mobileOrder="leftFirst"
          tabletAsMobile={true}
        />
      )}
    </>
  );
}
