import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Card from '../components/Card';
import LoadingSpinner from "../components/LoadingSpinner";
import {getAllCocktailCards} from "../api/Cocktail";
import {Title} from "../themes/styled";
import ErrorPage from "../themes/component/ErrorPage";

const mapCocktailToCard = (cocktail) => ({
  title: cocktail.nomeCocktail,
  image: cocktail.urlImgCocktail,
  id: cocktail.idCocktail
});

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 card per riga su desktop */
    gap: 16px;

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr); /* 2 card per riga su mobile */
    }
`;

function CardGrid({cocktails}) {

  return (
    <GridContainer>
      {cocktails.map((cocktail, index) => (
        <Card key={index} index={cocktail.id} title={cocktail.title} image={cocktail.image}/>
      ))}
    </GridContainer>
  );
}


export default function Cocktails() {
  const [cocktails, setCocktails] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    getAllCocktailCards()
      .then(data => {
        const parsedCocktails = data.map(item => mapCocktailToCard(JSON.parse(item)));
        setCocktails(parsedCocktails);
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  if (error) {
    return <ErrorPage><h1>Failed To Fetch Cocktails</h1></ErrorPage>;
  }

  if (!cocktails) {
    return <LoadingSpinner/>
  }

  return (
    <>
      <Title>Cocktails</Title>
      <CardGrid cocktails={cocktails}/>
    </>
  );
}
