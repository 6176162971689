import React from 'react';
import styled, { keyframes } from 'styled-components';
import { theme } from '../themes/colors';

// Definizione dell'animazione
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Componente styled per lo spinner
const Spinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .spinner-border {
    width: 3rem;
    height: 3rem;
    border: 0.4em solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 0.4em solid ${props => props.color};
    animation: ${spin} 0.75s linear infinite;
  }
`;

// Componente React
function LoadingSpinner({ color = theme.primaryColor }) {
  return (
    <Spinner color={color}>
      <div className="spinner-border"></div>
    </Spinner>
  );
}

export default LoadingSpinner;
