import React from 'react';
import styled from 'styled-components';
import {Title} from '../../themes/styled';
import DoubleColumnComponent from '../../themes/DoubleColumnComponent';

const backgroundImageUrl = 'https://liquorebonito.it/background/background.jpg';

const ContactDiv = styled.div`
    background-image: url(${backgroundImageUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0 80px;

    @media (max-width: 768px) {
        padding: 0 20px;
    }
`;

const TextContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        padding: 0;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        align-items: center;
        display: flex;
        justify-content: center;
        order: ${props => props.mobileorderright};
    }
`;

const ContactItem = styled.p`
    position: relative;
    margin-bottom: 5px;
    padding-bottom: 5px;
    padding-left: 100px;
    font-size: 1.5rem;

    @media (max-width: 768px) {
        padding-bottom: 0;
        margin-bottom: 0;
        padding-left: 0;
        text-align: center;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        padding-bottom: 0;
        margin-bottom: 0;
        padding-left: 0;
        text-align: center;
    }
`;

const ContactText = styled.p`
    font-size: 1.5rem;
`;

const ThreeColumnContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const Column = styled.div`
    flex: 1;
    padding: 10px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const CloseParagraph = styled.p`
    font-size: 1.5rem;
`;

export default function Contacts({content}) {
  return (
    <ContactDiv>
      <Title>Contact Us</Title>
      <DoubleColumnComponent
        leftContent={
          <TextContainer>
            <ContactText>{content.text}</ContactText>
          </TextContainer>
        }
        rightContent={
          <TextContainer>
            <ContactItem>{content.email}</ContactItem>
            <ContactItem>{content.phone}</ContactItem>
          </TextContainer>
        }
        leftColumnWidth="50"
        mobileOrder="leftFirst"
        tabletAsMobile={true}
      />
      <ThreeColumnContainer>
        <Column>
          <CloseParagraph>P. IVA</CloseParagraph>
          <CloseParagraph>{content.pIva}</CloseParagraph>
        </Column>
        <Column>
          <CloseParagraph>PEC</CloseParagraph>
          <CloseParagraph>{content.pec}</CloseParagraph>
        </Column>
        <Column>
          <CloseParagraph>Privacy Policy</CloseParagraph>
        </Column>
      </ThreeColumnContainer>
    </ContactDiv>
  );
}
