import React from 'react';
import styled from 'styled-components';
import Card from '../Card';
import { Title } from '../../themes/styled';
import { Link } from "react-router-dom";

const backgroundImageUrl = 'https://liquorebonito.it/background/background.jpg';

const HypeCocktailsContainer = styled.div`
    background-image: url(${backgroundImageUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0 80px;
    @media (max-width: 768px) {
        padding: 0 16px; /* Riduci il padding sui dispositivi mobili */
    }
`;

const Wrapper = styled.div`
    max-width: 80%;
    margin: 0 auto;
    padding: 16px;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        align-items: center;
        justify-content: center;
        align-content: center;
    }
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 16px;

    @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
`;

const Text = styled.p`
    width: 100%;
    text-align: justify;
    font-size: 1.5rem;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end; /* Allinea il contenuto a destra */
    margin-top: 16px;
    margin-bottom: 32px;
`;

const Button = styled(Link)`
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border: none;
    padding: 20px;
    border-radius: 15px;
    cursor: pointer;
    font-size: 1.5rem;
    width: 15%;
    text-decoration: none;
    text-align: center;

    &:hover {
        opacity: 0.9;
    }

    @media (max-width: 768px) {
        width: 200px;
        margin-left: auto;
        margin-right: auto;
    }
`;

export default function HypeCocktails({ text, cardData }) {
  const parseCocktails = (cocktailsJSON) => {
    return JSON.parse(cocktailsJSON);
  };

  const parsedCardData = cardData.map((card) => parseCocktails(card));

  return (
    <HypeCocktailsContainer>
      <Title>Cocktails</Title>
      <Text>{text}</Text>
      <Wrapper>
        <GridContainer id={"grid"}>
          {parsedCardData.slice(0, 3).map((card, index) => (
            <Card
              key={card.idCocktail}
              index={card.idCocktail}
              title={card.nomeCocktail}
              image={card.urlImgCocktail}
            />
          ))}
        </GridContainer>
        <ButtonWrapper>
          <Button to="/cocktails">Altri</Button>
        </ButtonWrapper>
      </Wrapper>
    </HypeCocktailsContainer>
  );
}
